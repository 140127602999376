import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ContactLayout from '../components/PagesLayout/contact';

const Contact = (props) => <ContactLayout {...props} />;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
         query ContactQuery($locale: String!) {
           contact: prismicContactPage(lang: { eq: $locale }) {
             uid
             lang
             data {
               image {
                 localFile {
                   childImageSharp {
                     fluid(toFormat: JPG) {
                       ...GatsbyImageSharpFluid_noBase64
                     }
                   }
                 }
               }
               company_label {
                 text
               }
               company_placeholder {
                 text
               }
               email_label {
                 text
               }
               email_placeholder {
                 text
               }
               full_name_label {
                 text
               }
               full_name_placeholder {
                 text
               }
               optional_label {
                 text
               }
               optional_placeholder {
                 text
               }
               submit_button_text {
                 text
               }
               textarea_label {
                 text
               }
               textarea_placeholder {
                 text
               }
               title {
                 text
               }
               thank_you_message {
                 text
               }
             }
           }
         }
       `;
